<template>
  <div>
    <div class="row justify-content-center align-items-md-center">
      <div class="col-md-12">
        <div class="card full-height bg-ligth">
          <div class="card-header">
            <h3 class="mb-0">
              {{ lista_campos != "" ? "Editar" : "Criar" }} pedido de compra
            </h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <form action="">
                  <div class="form-group row">
                    <div class="col-md-12">
                      <label class="col-md-12">Selecione Responsavel Por despesa :*</label>
                      <select class="form-control" v-model="tipo">
                        <option value="usuario">Usuario do sistema</option>
                        <option value="fornecedor">Fornecedor</option>
                      </select>
                    </div>
                    <div v-if="tipo == 'fornecedor'" class="col-md-12">
                      <label class="col-md-12">Selecione o Fornecedor :*</label>
                      <treeselect
                        :multiple="false"
                        :async="true"
                        required
                        placeholder="Pesquisar fornecedor"
                        v-model="form.pedido.fornecedor_id"
                        :load-options="(params) => searchFornecedor(params)"
                      />
                    </div>
                       <div v-if="tipo == 'usuario'" class="col-md-12">
                      <label class="col-md-12">Selecione o Usuario :*</label>
                      <treeselect
                        :multiple="false"
                        :async="true"
                        required
                        placeholder="Pesquisar Usuario"
                        v-model="form.pedido.usuario_id"
                        :load-options="(params) => searchUsuario(params)"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6">
                      <label class="col-md-12">Emissao:*</label>
                      <input
                        required
                        type="date"
                        class="form-control"
                        v-model="form.pedido.emissao"
                        placeholder="Digite o nome..."
                      />
                    </div>
                    <div class="col-md-6">
                      <label class="col-md-12">Data Evento:*</label>
                      <input
                        required
                        type="date"
                        class="form-control"
                        v-model="form.pedido.data_entrega"
                        placeholder="Digite o nome..."
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6">
                      <label class="col-md-12">Moeda :*</label>
                      <select
                        class="form-control"
                        v-model="form.pedido.finan_moeda_id"
                      >
                        <option
                          v-for="(lista, index) in lista_finan_moedas"
                          :key="index"
                          :value="lista.id"
                        >
                          {{ lista.nome }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-6">
                      <label class="col-md-12">Valor total :*</label>
                      <money
                        required
                        class="form-control"
                        v-bind="{ decimal: ',', thousands: '.', prefix: 'R$ ' }"
                        v-model="form.pedido.valor_total"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6">
                      <label class="col-md-12">Departamento :*</label>
                      <select class="form-control" v-model="departamento_id">
                        <option
                          v-for="(lista, index) in lista_finan_departamento"
                          :key="index"
                          :value="lista.id"
                        >
                          {{ lista.nome }}
                        </option>
                      </select>
                    </div>
                    <div class="col-md-6">
                      <label class="col-md-12">Centro Custo :*</label>
                      <select
                        :disabled="departamento_id === null"
                        class="form-control"
                        v-model="form.pedido.centro_custo_departamento_id"
                      >
                        <option
                          v-for="(
                            lista, index
                          ) in centro_custo_from_selected_departamento"
                          :key="index"
                          :value="lista.pivot.id"
                        >
                          {{ lista.nome }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6">
                      <label class="col-md-12"
                        >Selecione Forma de Pagamento :*</label
                      >
                      <select
                        class="form-control"
                        v-model="form.pedido.finan_forma_pagamento_id"
                      >
                        <option
                          v-for="(lista, index) in lista_finan_forma_pagamentos"
                          :key="index"
                          :value="lista.id"
                        >
                          {{ lista.nome }}
                        </option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
              <div class="col-md-6 border-left"></div>

              <!-- <div class="col-md-6 border-left">
                <div class="d-flex justify-content-end w-100">
                  <button @click="showModalItens()" class="btn btn-info">
                    Adicionar Itens
                  </button>
                </div>
                <div class="table-responsive mt-5">
                  <b-table
                      class="table table-head-custom table-vertical-center table-head-bg table-borderless"
                      :items="lista_itens_produto"
                      show-empty
                      empty-text="Nenhum registro encontrado!"
                      :fields="['nome_produto','quantidade', 'valor_unitario', 'acao']"
                  >
                    <template #head(acao)=""><span></span></template>
                    <template #cell(valor_unitario)="{item}">
                      {{ item.valor_unitario | formatMoney }}
                    </template>
                    <template #cell(acao)="{index}">
                      <div class="text-right w-100">
                        <a href="#" @click.prevent="deleteItemProduto(index)"
                           class="btn btn-icon btn-light btn-sm">
                          <span class="svg-icon svg-icon-md svg-icon-danger">
                            <inline-svg src="media/svg/icons/General/Trash.svg"></inline-svg>
                          </span>
                        </a>
                      </div>
                    </template>

                    <template #custom-foot="{items}">
                      <div class="ml-2" v-if="items.length">
                        <strong>Total:</strong> {{ valorTotal | formatMoney }}
                        <br>
                        <strong>Desconto:</strong> {{ totalDesconto | formatMoney }}
                      </div>
                    </template>

                  </b-table>
                </div>
              </div> -->
            </div>

            <div class="row">
              <div class="col-md-12">
                <hr />
                <div class="form-group mt-10">
                  <div class="col-md-12 text-center">
                    <button
                      @click="confirm(lista_campos == '' ? 'cria' : 'edita')"
                      class="btn btn-primary"
                      :disabled="verif"
                    >
                      Salvar
                      <b-spinner
                        v-show="verif"
                        small
                        variant="dark"
                        type="grow"
                        label="Spinning"
                      ></b-spinner>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <b-modal
      class="bg-primary"
      ref="modalitens"
      size="md"
      title="Cricao dos Itens do Pedido"
    >
      <form class="mt-4">
        <div class="form-group row">
          <div class="col-md-12">
            <label class="col-md-12">Selecione o Produto :*</label>
            <treeselect
              :multiple="false"
              :async="true"
              placeholder="Pesquisar produto"
              required
              v-model="itens.produto_id"
              :load-options="(params) => searchProdutos(params)"
            />
          </div>
        </div>

        <div class="form-group row">
          <div class="col-md-12">
            <label class="col-md-12">Quantidade:*</label>
            <input
              required
              type="text"
              class="form-control"
              v-model="itens.quantidade"
              placeholder="Digite o nome..."
            />
          </div>
        </div>
      </form>

      <template v-slot:modal-footer="{}">
        <div class="d-flex justify-content-between w-100">
          <b-button
            size="sm"
            variant="secondary"
            @click.prevent="closeModalItens"
          >
            Cerrar
          </b-button>
          <b-button @click.prevent="salvar_itens()" variant="info" size="sm">
            Adicionar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { fireAlert } from "@/core/mixins/alertMessageMixin";
import moneyFormat from "@/core/mixins/moneyFormat";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  mixins: [fireAlert, moneyFormat],
  data() {
    return {
      departamento_id: null,
      tipo:'',
      form: {
        pedido: {
          valor_total: 0,
          emissao: "",
          data_entrega: "",
          filial_id: "",
          finan_forma_pagamento_id: "",
          finan_caixa_id: "",
          finan_moeda_id: "",
          centro_custo_departamento_id: "",
          fornecedor_id: null,
          usuario_id: null,
          status: 1,
        },
        itens: [],
      },
      itens: {
        valor_unitario: null,
        nome_produto: null,
        produto_id: null,
        quantidade: null,
        valor_desconto: "",
      },
      verif: false,
    };
  },

  created() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Pedido Compra" }]);
    this.preenxerCampos();
    this.listar_requerimentos();
    this.listar_finan_moeda();
  },
  computed: {
    totalDesconto() {
      return this.lista_itens_produto.reduce((curr, item) => {
        return Number(curr) + Number(item.valor_desconto);
      }, 0);
    },
    valorTotal() {
      return this.lista_itens_produto.reduce((curr, item) => {
        return curr + item.valor_unitario * item.quantidade;
      }, 0);
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },
    mensagem_alert() {
      return this.$store.state.configEmpresa.mensagem_alert;
    },
    lista_usuarios() {
      return this.$store.state.configFuncionario.lista_usuarios;
    },
    lista_finan_moedas() {
      return this.$store.state.financeiro.lista_finan_moedas;
    },
    lista_finan_modo_pagamentos() {
      return this.$store.state.financeiro.lista_finan_modo_pagamentos;
    },
    lista_finan_forma_pagamentos() {
      return this.$store.state.financeiro.lista_finan_forma_pagamentos;
    },
    lista_finan_caixas() {
      return this.$store.state.financeiro.lista_finan_caixas;
    },
    lista_filials() {
      return this.$store.state.configEmpresa.lista_filials;
    },
    lista_produtos() {
      return this.$store.state.financeiro.lista_produtos;
    },
    lista_itens_produto() {
      return this.$store.state.financeiro.lista_itens_produto;
    },
    lista_finan_centro_custo() {
      return this.$store.state.finanOrcamentoAnual.lista_finan_centro_custo;
    },
    lista_finan_departamento() {
      return this.$store.state.finanOrcamentoAnual.lista_finan_departamento;
    },
    centro_custo_from_selected_departamento() {
      if (!this.departamento_id) return [];
      return this.$store.state.finanOrcamentoAnual.lista_finan_departamento.find(
        ({ id }) => id === this.departamento_id
      ).centro_custo;
    },
  },

  methods: {
    async listar_finan_moeda() {
      this.$store.dispatch("MudarPreloader", true);
      await this.$store.dispatch("listar_finan_moeda").finally(() => {
        this.$store.dispatch("MudarPreloader", false);
        this.$store.dispatch("atualizar", "");
      });
    },
     searchUsuario({ searchQuery, callback }) {
      ApiService.get(`usuario/pesquisa/${searchQuery}`).then(({ data }) => {
        let options = data.map((u) => ({
          id: u.id,
          label: u.nome+' '+u.sobrenome,
        }));
        callback(null, options);
      });
    },

    searchFornecedor({ searchQuery, callback }) {
      ApiService.get(`fornecedor/pesquisa/${searchQuery}`).then(({ data }) => {
        let options = data.map((produto) => ({
          id: produto.id,
          label: produto.nome_fantasia,
        }));
        callback(null, options);
      });
    },
    searchProdutos({ searchQuery, callback }) {
      ApiService.get(`produto/pesquisa/${searchQuery}`).then(({ data }) => {
        let options = data.map((produto) => ({
          id: produto.id,
          label: `${produto.nome} | R$ ${produto.valor}`,
        }));
        callback(null, options);
      });
    },
    deleteItemProduto(index) {
      if (!this.lista_itens_produto[index].id) {
        this.lista_itens_produto.splice(index, 1);
        return;
      }
      this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação irá remover o produto da sua lista`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("MudarPreloader", true);
            this.$store
              .dispatch("delete_produto_pedido_recebimento", index)
              .finally(() => this.$store.dispatch("MudarPreloader", false));
          }
        },
      });
    },
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação ` + tipo + ` uma Pedido Compra no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
            if (tipo == "edita") this.update();
          }
        },
      });
    },
    async create() {
      this.verif = true;
      this.form.itens = this.lista_itens_produto;
      await this.$store.dispatch("create_finan_pedido_compra", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "pedidoCompra",
      });
    },
    async update() {
      this.verif = true;
      await this.$store.dispatch("update_finan_pedido_compra", this.form);
      this.verif = false;
      this.fireAlert({
        ...this.mensagem_alert,
        routeName: "pedidoCompra",
      });
    },
    preenxerCampos() {
      if (this.lista_campos != "")
        this.form = {
          pedido: {
            id: this.lista_campos.id,
            filial_id: this.lista_campos.filial_id,
            descricao: this.lista_campos.descricao,
            valor_total: this.lista_campos.valor_total,
            centro_custo_departamento_id: this.lista_campos
              .centro_custo_departamento_id,
            status: this.lista_campos.status,
            emissao: this.lista_campos.emissao,
            data_entrega: this.lista_campos.data_entrega,
            finan_forma_pagamento_id: this.lista_campos
              .finan_forma_pagamento_id,
            finan_cambio_id: this.lista_campos.fornecedor_id,
            finan_caixa_id: this.lista_campos.finan_caixa_id,
            fornecedor_id: this.lista_campos.fornecedor_id,
          },
        };
    },
    async listar_requerimentos() {
      this.$store.dispatch("limpar_lista_produtos");
      return Promise.all([
        this.$store.dispatch("listar_finan_caixa"),
        this.$store.dispatch("listar_finan_modo_pagamento"),
        this.$store.dispatch("listar_finan_forma_pagamento"),
        this.$store.dispatch("listar_finan_moeda"),
        this.$store.dispatch("listar_finan_cambio"),
        this.$store.dispatch("listar_produto"),
        this.$store.dispatch("listar_usuarios"),
        this.$store.dispatch("listar_finan_departamento"),
        this.$store.dispatch("listar_finan_centro_custo"),
      ]);
    },
    showModalItens() {
      this.$refs["modalitens"].show();
      this.itens.produto_id = null;
      this.itens.nome_produto = "";
      this.itens.valor_unitario = "";
      this.itens.quantidade = "";
      this.itens.valor_desconto = "";
    },
    closeModalItens() {
      this.$refs["modalitens"].hide();
    },
    async salvar_itens() {
      this.itens.valor_unitario = this.lista_produtos.find((item) => {
        return item.id === this.itens.produto_id;
      }).valor;
      await this.$store.dispatch("guardar_itens", this.itens);
      this.closeModalItens();
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgba(167, 183, 255, 0.705);
}
</style>